
import chineseTWMessages from "ra-language-chinese-traditional";

export const zhTW = {
    ...chineseTWMessages,
    resources: {
        niches: {
            name: '靈位',
            fields: {
				section: "區域",
				id: "系統編號",
				sold_at: "售賣日期",
				receipt_num: "收據號碼",
				tablet_id: "牌位編號",
				niche_id: "新編號",
				ancestor_surname:"安奉祖先姓",
				ancestor_fullname:"安奉祖先姓名",
				ancestor_occupied:"安奉",
				contact_person: "聯絡人",
				contact_person_addr: "地址",
				contact_person_phone: "電話",
				remarks: "備註",
				files: "附件",
				created_at: "建立時間",
				updated_at: "最後修改日期",
				last_edited_by: "最後修改者"
            },
        },
        sections: {
            name: '區域',
            fields: {
				id: "系統編號",
				name: "區域",
				niche_total: "龕位共有",
				niche_sold: "己售出",
				niche_available: "可以售賣",
				created_at: "建立時間",
				updated_at: "最後修改日期",
				last_edited_by: "最後修改者"				
            }
        },
		"contact-persons":{
			name: '聯絡人',
            fields: {
				id: "系統編號",
				fullname: "名稱",
				name: "名稱",
				contact_addr: "地址",
				phone: "電話",
				created_at: "建立時間",
				updated_at: "最後修改日期",
				last_edited_by: "最後修改者"				
            }
		},
		ceremonies: {
			name: '法會',
            fields: {
				id: "系統編號",
				title: "法會",
				started_at: "舉辦日期",
				small_tablet_total: "小龍總數",
				small_tablet_order: "小龍訂單數",
				small_tablet_paid: "小龍已付款數",
				large_tablet_total: "大龍總數",
				large_tablet_order: "大龍訂單數",
				large_tablet_paid: "大龍已付款數",
				extra_large_tablet_total: "特大龍總數",
				extra_large_tablet_order: "特大龍訂單數",
				extra_large_tablet_paid: "特大龍已付款數",
				normal_tablet_total: "普通總數",
				normal_tablet_order: "普通訂單數",
				normal_tablet_paid: "普通已付款數",
				created_at: "建立時間",
				updated_at: "最後修改日期",
				last_edited_by: "最後修改者"				
            }
		},
		"ceremony-tablets": {
			name: '牌位',
            fields: {
				id: "系統編號",
				size: "尺寸",
				tablet_id: "編號",
				content: "內容",
				address: "陽居",
				remarks: "備註",
				paid: "已付",
				sheet: "頁號"
			}
		},
		blessings: {
			name: '祈福',
			fields: {
				id: "系統編號",
				title: "祈福",
				started_at: "舉辦日期",
				created_at: "建立時間",
				updated_at: "最後修改日期",
				last_edited_by: "最後修改者"	
			}
		},
		amulets: {
			name: '護身符',
			fields: {
				id: "系統編號",
				title: "標題",
				subtitle: "副標題",
				type: "類別",
				created_at: "建立時間",
				updated_at: "最後修改日期",
				last_edited_by: "最後修改者"	
			}
		},
		rooms: {
			name: '長生位區域',
			fields: {
				id: "系統編號",
				name: "長生位區域",
				tablet_total: "長生位共有",
				tablet_sold: "己售出",
				tablet_available: "可以售賣",
				created_at: "建立時間",
				updated_at: "最後修改日期",
				last_edited_by: "最後修改者"				
            }
		},
		'memorial-tablets': {
            name: '長生位',
            fields: {
				section: "區域",
				id: "系統編號",
				sold_at: "售賣日期",
				receipt_num: "收據號碼",
				tablet_id: "牌位編號",
				ancestor_surname:"安奉祖先姓",
				ancestor_fullname:"安奉祖先姓名",
				ancestor_occupied:"安奉",
				contact_person: "聯絡人",
				contact_person_addr: "地址",
				contact_person_phone: "電話",
				remarks: "備註",
				files: "附件",
				created_at: "建立時間",
				updated_at: "最後修改日期",
				last_edited_by: "最後修改者"
            },
        },
		members: {
			name: '會員',
			fields: {
				id: "系統編號",
				member_id: "編號",
				fullname: "名稱",
				expiry_year: "會籍有效期至",
				registered_at: "入會日期",
				membership_fee: "會費",
				receipt_num: "收據號碼",
				valid: "會籍",
				paid: "繳交會費狀況",
				fee_excluded: "豁免年費",
				can_contact: "可接洽",
				contact_addr: "地址",
				phone: "電話",
				hkid: "身份證號碼",
				remarks: "備註/其他",
				created_at: "建立時間",
				updated_at: "最後修改日期",
				last_edited_by: "最後修改者"			
            }
		},
		'member-details': {
            fields: {
				id: "系統編號",
				member_id: "會員編號",
				year: "年份",
				receipt_num: "三聯單號碼",
				valid_from_year: "會費有效期由",
				valid_to_year: "會費有效期至",
				fee_paid: "銀碼",
				paid_at: "日期",
				remarks: "備註",
				attachment: "附件",
				created_at: "建立時間",
				updated_at: "最後修改日期",
				last_edited_by: "最後修改者"
            },
        },
    },
};

export default zhTW;
