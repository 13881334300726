import { Admin, CustomRoutes, Resource } from "react-admin";
import authProvider from "./authProvider";
import dataProvider from "./dataProvider";
import Login from "./LoginPage";
import {
	NicheList,
	NicheEdit,
	NicheCreate,
	NicheShow,
} from "./components/niches";
import {
	SectionList,
	SectionEdit,
	SectionCreate,
	SectionShow,
} from "./components/sections";
import {
	ContactPersonList,
	ContactPersonEdit,
	ContactPersonCreate,
	ContactPersonShow,
} from "./components/contact-persons";
import i18nProvider from "./i18nProvider";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import SplitscreenIcon from "@mui/icons-material/Splitscreen";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import theme from "./theme";
import { MyLayout } from "./layout/MyLayout";
import { Route } from "react-router-dom";
import {
	CeremonyCreate,
	CeremonyEdit,
	CeremonyList,
	CeremonyShow,
} from "./components/ceremonies";
import {
	BlessingList,
	BlessingCreate,
	BlessingEdit,
	BlessingShow,
} from "./components/blessings";
import { RoomList, RoomCreate, RoomEdit, RoomShow } from "./components/rooms";
import {
	MemorialTabletList,
	MemorialTabletCreate,
	MemorialTabletEdit,
	MemorialTabletShow,
} from "./components/memorial-tablets";
import { Box } from "@mui/material";
import { MemberList, MemberCreate, MemberEdit, MemberShow } from "./components/members";
import { MemberDetailsCreate, MemberDetailsEdit } from "./components/member-details";
// import Settings from "./components/change-password";

const MyAdmin = () => (
	<Admin
		theme={theme}
		layout={MyLayout}
		title="忠和精舍"
		loginPage={Login}
		dataProvider={dataProvider}
		authProvider={authProvider}
		i18nProvider={i18nProvider}
		requireAuth>
		{(permissions) => (
			<>
				<Resource
					name="niches"
					options={{ label: "Niches" }}
					icon={Box}
					list={NicheList}
					create={permissions !== "30" ? NicheCreate : undefined}
					edit={permissions !== "30" ? NicheEdit : undefined}
					show={NicheShow}
				/>
				<Resource
					name="contact-persons"
					recordRepresentation="fullname"
					options={{ label: "Contact Persons" }}
					icon={Box}
					list={ContactPersonList}
					create={
						permissions !== "30" ? ContactPersonCreate : undefined
					}
					edit={permissions !== "30" ? ContactPersonEdit : undefined}
					show={ContactPersonShow}
				/>
				<Resource
					name="sections"
					options={{ label: "Sections" }}
					icon={Box}
					list={SectionList}
					create={permissions !== "30" ? SectionCreate : undefined}
					edit={permissions !== "30" ? SectionEdit : undefined}
					show={SectionShow}
				/>
				<Resource
					name="ceremonies"
					options={{ label: "Ceremony" }}
					icon={Box}
					list={CeremonyList}
					create={permissions !== "30" ? CeremonyCreate : undefined}
					edit={permissions !== "30" ? CeremonyEdit : undefined}
					show={CeremonyShow}
				/>
				<Resource
					name="blessings"
					options={{ label: "Blessing" }}
					icon={Box}
					list={BlessingList}
					create={permissions !== "30" ? BlessingCreate : undefined}
					edit={permissions !== "30" ? BlessingEdit : undefined}
					show={BlessingShow}
				/>
				<Resource name="ceremony-tablets" />
				<Resource name="amulets" />
				<Resource
					name="rooms"
					options={{ label: "Rooms" }}
					icon={Box}
					list={RoomList}
					create={permissions !== "30" ? RoomCreate: undefined}
					edit={permissions !== "30" ? RoomEdit: undefined}
					show={RoomShow}
				/>
				<Resource
					name="memorial-tablets"
					options={{ label: "MemorialTablets" }}
					icon={Box}
					list={MemorialTabletList}
					create={permissions !== "30" ? MemorialTabletCreate: undefined}
					edit={permissions !== "30" ? MemorialTabletEdit : undefined}
					show={MemorialTabletShow}
				/>
				<Resource
					name="members"
					options={{ label: "Members" }}
					icon={Box}
					list={MemberList}
					create={
						permissions !== "30" ? MemberCreate : undefined
					}
					edit={permissions !== "30" ? MemberEdit : undefined}
					show={MemberShow}
				/>
				<Resource 
					name="member-details"
					create={
						permissions !== "30" ? MemberDetailsCreate : undefined
					}
					edit={permissions !== "30" ? MemberDetailsEdit : undefined}
				/>
				{/* <CustomRoutes>
				<Route path="/change-password" element={<Settings />} />
			</CustomRoutes> */}
			</>
		)}
	</Admin>
);

export default MyAdmin;
