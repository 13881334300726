import * as React from "react";
import { Box, Chip, useMediaQuery } from "@mui/material";
import {
	List,
	Datagrid,
	TextField,
	ReferenceField,
	EditButton,
	Edit,
	SimpleForm,
	ReferenceInput,
	SelectInput,
	TextInput,
	Create,
	required,
	ShowButton,
	DateField,
	SearchInput,
	useTranslate,
	Show,
	SimpleShowLayout,
	DateInput,
	DateTimeInput,
	AutocompleteInput,
	ImageInput,
	ImageField,
	FileField,
	useDataProvider,
	FunctionField,
	WithRecord,
	downloadCSV,
	Exporter,
	usePermissions,
} from "react-admin";
import jsonExport from 'jsonexport/dist';
import { ImageDownloadField } from "./react-admin/imageDownloadField";
import { fetchUtils } from "ra-core";
import { authHeaders } from "../customDataProvider";

const QuickFilter = ({
	label,
	source,
	defaultValue,
}: {
	label: string;
	source: string;
	defaultValue: string | undefined;
}) => {
	const translate = useTranslate();
	return <Chip sx={{ marginBottom: 1 }} label={translate(label)} />;
};

const exporter: Exporter = () => {
	const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
	const url = `${apiUrl}/memorial-tablets/export`;
	const headers = authHeaders();

	fetchUtils.fetchJson(url, {
		headers,
		method: 'POST'
	}).then(({ json }) => {
		return jsonExport(json, {
			headers: ['id', 'room', 'sold_at', 'receipt_num', 'tablet_id', 'ancestor_surname', 'ancestor_fullname', 'ancestor_occupied', 'contact_person', 'contact_person_addr', 'contact_person_phone', 'remarks', 'updated_at', 'last_edited_by'],
			rename: ['系統編號', '區域', '售賣日期', '收據號碼', '牌位編號', '安奉祖先姓', '安奉祖先姓名', '安奉', '聯絡人', '地址', '電話', '備註', '最後修改日期', '最後修改者'],
		}, (err, csv) => {
			downloadCSV(csv, 'memorialTablets');
		});
	});
};

const memorialTabletFilter = [
	// <SearchInput source="q" alwaysOn />,
	<TextInput source="ancestor_fullname" defaultValue="" />,
	<TextInput source="receipt_num" defaultValue="" />,
	<ReferenceInput
		label="resources.contact-persons.name" 
		source="contact_person_id" 
		reference="contact-persons"
	>
		<AutocompleteInput
			optionText={(option) => `${option.fullname} - ${option.contact_addr} - ${option.phone}`}
			label="resources.contact-persons.name"
			fullWidth
		/>
	</ReferenceInput>
	// <QuickFilter
	// 	label="resources.memorialTablets.fields.ancestor_fullname"
	// 	source="ancestor_fullname"
	// 	defaultValue=""
	// />,
];

export const MemorialTabletList = () => {
	const { permissions } = usePermissions();
	
	return (
		<List filters={memorialTabletFilter} exporter={exporter}>
			<Datagrid isRowSelectable={(_) => permissions !== "30"}>
				<ReferenceField
					label="resources.rooms.name"
					source="room_id"
					reference="rooms">
					<TextField source="name" label="room" />
				</ReferenceField>
				{/* <TextField source="id" /> */}
				<DateField source="sold_at" />
				<TextField source="receipt_num" />
				<TextField source="tablet_id" />
				<TextField source="ancestor_surname" />
				<TextField source="ancestor_fullname" />
				<TextField source="ancestor_occupied" />
				<ReferenceField
					label="resources.contact-persons.name"
					source="contact_person_id"
					reference="contact-persons">
					<TextField source="fullname" />
				</ReferenceField>
				<ReferenceField
					label="resources.contact-persons.fields.contact_addr"
					source="contact_person_id"
					reference="contact-persons">
					<TextField source="contact_addr" />
				</ReferenceField>
				<ReferenceField
					label="resources.contact-persons.fields.phone"
					source="contact_person_id"
					reference="contact-persons">
					<TextField source="phone" />
				</ReferenceField>
				<TextField source="remarks" />
				{/* <DateField showTime={true} source="created_at" /> */}
				{/* <DateField showTime={true} source="updated_at" /> */}
				{/* <TextField source="last_edited_by" /> */}
				{
					permissions !== "30" &&
					<EditButton />
				}
				<ShowButton />
			</Datagrid>
		</List>
	)
};

export const MemorialTabletCreate = () => (
	<Create>
		<SimpleForm>
			<ReferenceInput source="room_id" reference="rooms">
				<SelectInput
					optionText="name"
					label="resources.rooms.name"
				/>
			</ReferenceInput>
			{/* <TextField source="id" /> */}
			<DateInput source="sold_at" />
			<TextInput source="receipt_num" />
			<TextInput source="tablet_id" type="number" />
			<TextInput source="ancestor_surname" />
			<TextInput source="ancestor_fullname" />
			<SelectInput
				source="ancestor_occupied"
				optionValue="name"
				optionText="name"
				choices={[{ name: "Y" }, { name: "N" }, { name: "O" }]}
			/>
			<ReferenceInput
				source="contact_person_id"
				reference="contact-persons">
				<AutocompleteInput
					optionText={(option) => `${option.fullname} - ${option.contact_addr} - ${option.phone}`}
					label="resources.contact-persons.name"
					fullWidth
				/>
			</ReferenceInput>
			<TextInput source="remarks" />
			<ImageInput
				source="attachment"
				multiple
				labelMultiple="ra.input.file.upload_several"
				maxSize={8000000}
				accept="image/*">
				<ImageField source="src" title="title" />
			</ImageInput>
		</SimpleForm>
	</Create>
);

export const MemorialTabletEdit = () => (
	<Edit>
		<SimpleForm>
			<ReferenceInput source="room_id" reference="rooms">
				<SelectInput
					optionText="name"
					label="resources.rooms.name"
					validate={[required()]}
					fullWidth
				/>
			</ReferenceInput>
			{/* <TextField source="id" /> */}
			<DateInput source="sold_at" fullWidth />
			<TextInput source="receipt_num" fullWidth />
			<TextInput source="tablet_id" validate={[required()]} fullWidth />
			<TextInput
				source="ancestor_surname"
				validate={[required()]}
				fullWidth
			/>
			<TextInput
				source="ancestor_fullname"
				validate={[required()]}
				fullWidth
			/>
			<SelectInput
				source="ancestor_occupied"
				optionValue="name"
				optionText="name"
				choices={[{ name: "Y" }, { name: "N" }, { name: "O" }]}
				validate={[required()]}
				fullWidth
			/>
			<ReferenceInput
				source="contact_person_id"
				reference="contact-persons">
				<AutocompleteInput
					optionText={(option) => `${option.fullname} - ${option.contact_addr} - ${option.phone}`}
					label="resources.contact-persons.name"
					validate={[required()]}
					fullWidth
				/>
			</ReferenceInput>
			<TextInput source="remarks" fullWidth />
			<WithRecord label="attachment.download" render={record => (
				<ImageDownloadField images={record.attachment} />
			)} />
			<ImageInput
				source="attachment"
				multiple
				labelMultiple="ra.input.file.upload_several"
				maxSize={8000000}
				accept="image/*"
			>
				<ImageField source="src" title="title" />
			</ImageInput>
			<DateTimeInput disabled source="created_at" fullWidth />
			<DateTimeInput disabled source="updated_at" fullWidth />
			<TextInput disabled source="last_edited_by" fullWidth />
		</SimpleForm>
	</Edit>
);

export const MemorialTabletShow = () => (
	<Show>
		<SimpleShowLayout>
			<ReferenceField
				label="resources.rooms.name"
				source="room_id"
				reference="rooms">
				<TextField source="name" label="room" />
			</ReferenceField>
			<DateField source="sold_at" />
			<TextField source="receipt_num" />
			<TextField source="tablet_id" />
			<TextField source="ancestor_surname" />
			<TextField source="ancestor_fullname" />
			<TextField source="ancestor_occupied" />
			<ReferenceField
				label="resources.contact-persons.name"
				source="contact_person_id"
				reference="contact-persons">
				<TextField source="fullname" />
			</ReferenceField>
			<ReferenceField
				label="resources.contact-persons.fields.contact_addr"
				source="contact_person_id"
				reference="contact-persons">
				<TextField source="contact_addr" />
			</ReferenceField>
			<ReferenceField
				label="resources.contact-persons.fields.phone"
				source="contact_person_id"
				reference="contact-persons">
				<TextField source="phone" />
			</ReferenceField>
			<TextField source="remarks" />
			<WithRecord label="附件" render={record => (
				<ImageDownloadField images={record.attachment} />
			)} />
			<DateField showTime={true} source="created_at" />
			<DateField showTime={true} source="updated_at" />
			<TextField source="last_edited_by" />
			<TextField source="id" />
		</SimpleShowLayout>
	</Show>
);
